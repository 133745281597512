.main-navigation {
    position:absolute;
    top:0;
    z-index:100;
    color: @brand-color;
    line-height: 21px;

    .hamburger:hover {
        cursor: pointer;
    }

    ul {
        background-color:#fff;
        font-size: rem(18px);
        line-height: rem(30px);
        
        color:#000;
    }
    li {
        list-style-type: none;
        line-height: rem(30px);
        cursor: pointer;
        display:block;
        padding: 8px 0 8px 20px;
        a {
            color: #000;
        }
    }

    .level1 {
        width: rem(380px);
        //TODO:
        box-shadow: 1px 1px  hsla(0, 0%, 0%, 0.25);
        
        &:hover {
            color:@dark-hover-color;
        }
        
        .book, .impressum {
            padding-left:20px;
        }
        .impressum:hover {
            color: @dark-hover-color;
        }
        .icon {
            width: 36px;
            height: 18px;
            color: @brand-color;
            position: absolute;
            right: 20px;
        }
        
        &.-active {
            .book:first-child {
                margin-left: -20px;
                padding: 6px 0 6px 37px;
                border-left: 3px solid @brand-color;
                color: @brand-color;
            }
            .icon {
                transform: rotate(180deg);
            }
            .level2 {
                background-color:#f6f6f6;
                padding-left: 27px;
                margin-left: -20px;
                
                li a{
                    display:block;
                }
                li:hover {
                    a {
                        color: @dark-hover-color;
                    }    
                }
            }
        }
    }
    .level1:first-child:hover {
        color: @hover-color;
    }
    .-close {
        display:none;
    }
}


@media only screen and (max-width: @small-break-point) {
    .main-navigation {
        .level1 {
            width: rem(340px);
        }
    }
}