.carousel-element {
    position: relative;
    
    &.-lightbox {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.6);
        overflow-y: scroll;
        top: 0px;
        left:0px;
        max-width:100%;
        height: 100%;
        z-index: 20;

        .close {
            display: block;
            position: fixed;
            height: 100%;
            right: 32px;
            top: 32px;
            width: 32px;
            height: 32px;
            z-index: 10;
            cursor:pointer;
        }


        .navi-left, .navi-right {
            width: 48px;
            height: 48px;
        }

        .navi-right {
            right: 0px;
            transform: rotate(180deg) translateY(0%);

        }
        .navi-left {
            left: 0px;
            transform: translateY(0%);
        }
        .outer-stage {
            height: 100%;
            overflow-y: auto;
            .inner-stage {
                height: 100%;
                
                .lightbox {
                    min-width: 100%;
                    width: 100%;
                    text-align: center;
                    .container{
                        width: 80%;
                        margin: 0 auto;
                        background-color: transparent;

                        .inner {
 

                            .image-container {
                                position: relative;
                                height: 100%;
                                max-height: 100vh;
                                margin-top: 100px;
                                display:inline-block;
                            }

                            .image {
                                margin: 0 auto;
                                display: block;
                                height: auto;
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }

                    .open {
                        display: none;
                    }
                    .caption-element {
                        display: none;
                    }
                }
            }
        }
    }


    .close {
        display:none;
    }

    .navi-left, .navi-right {
        position: absolute;
        top: 50%;
        cursor: pointer;
        z-index: 5;
        background-color: @brand-color;
        width:44px;
        height: 44px;
        svg {
            width: 44px;
            height:44px;
        }
    }

    .navi-left {
        left: 0px;
        transform:translateY(-50%)
    }

    .navi-right {
        right: 0px;
        transform: rotate(180deg) translateY(50%);
    }

    .outer-stage {
        overflow: hidden;
        .inner-stage {
            display: flex;
            align-items: center;
            justify-content: left;
            flex-direction: row;
            flex-wrap: nowrap;
            transition: left .4s ease-out;
            left: 0px;
            position: relative;

            .lightbox {
                //position:relative;
                height: 485px; //inkl caption
                .container {
                    background-color: #6A6A6A;
                    height: 450px;
                    width:100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    .image {
                        width: auto;
                        max-height: 450px;
                        margin: 0 auto;
                        display: block;   
                    }
                }
                .caption-element {
                    
                    display: block;
                    width: 100%;
                
                    margin-top: 16px;
                    
                }
            }
        }
    }

}
