.button-teacher {
    
    cursor: pointer;
    font-size: 18px;
    text-transform: uppercase;
    width: 400px;
    text-align:right;
    display:block;
    // display: flex;
    // justify-content: flex-end;
    // align-items: center;
    font-weight:bold;
    

    &:hover {
        color: @hover-color;
        
    }
    
    .icon {
        color: @brand-color;
        height: 26px;
        width: 26px;
        vertical-align: text-top;
        
        // margin-right:5px;
        // vertical-align: text-top;
        // &:hover {
            //color: @hover-color;
        // }
    }

    &.-close {
        text-align: left;
        color: #000;
        // justify-content:start;
        .icon {
            transform: rotate(-180deg);
        }
        &:hover {
            color: @hover-color;
        }
    }
}