.container-teacher {
    

    border-bottom: 2px solid @brand-color;
    
    &.sidebar-login {
        border-bottom: 0px;
    }


    &:last-child {
        border-bottom:none;
    }
    
    
    .module-title {
        padding-top: 20px;
        margin-bottom: 30px;
    }

    .title.title-download {
        border-bottom: 2px solid @brand-color;
        cursor: auto;
        margin: 0px;
        padding: 10px 0px;
    }

    .title {
        margin: 0px -18px 0px -27px;
        padding: 10px 0px 10px 27px;
        position:relative;
        cursor:pointer;

        &.-chapter {
            &.-current {
                border-left: 3px solid @brand-color;
                background-color: #E9E9E9;
            }
            &:hover {
                background-color: #E9E9E9;
            }
        }

        &.-background {
            &:hover {
                background-color: #E9E9E9;
            }
        }


        .title-content {
            display:inline-block;
            margin-right: 34px;
        }
        .icon {
            color: @brand-color;
            position: absolute;
            top: 14px;
            right: 17px;

        }
    }
    .content {
        padding-bottom: 30px;

        .content-section {
            margin-bottom: 20px;

            &.-inline {
                .section-title {
                    display:inline;
                    margin-right: 5px;
                }
            }

            .section-title {
                font-size: 18px;
                font-weight: bold;
                line-height: 23px;
                
                margin-bottom: 5px;
            }
            p {
                margin-bottom: 10px;
            }
            ul, ol {
                margin-left: 30px;

                li {
                    margin-bottom: 10px;
                }
            }

        }


        &.-close {
            display:none;
        }
    }

    .export {
        >.button-export {
            margin-right: 29px;
        }
        margin-top: 32px;

        .download {
            display: block;
            color: #000;
            margin: 11px 0px;
            &:hover {
                color: @brand-color;
                cursor: pointer;
            }

        }
        
        p {
            font-size: 18px;
            font-weight: bold;
            line-height: 23px;

            margin-bottom: 35px;
        }
    }
}



@media only screen and (max-width: @small-break-point) { 
    .container-teacher {
        .module-title {
            margin-bottom: 20px;
        }

        .export {
            margin-top: 20px;
            p {
                margin-bottom: 20px;
            }
        }

    }

}
