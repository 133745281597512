.question-item {
    margin-bottom: rem(60px);

    >.icon {
        display: inline-block;
        color: @brand-color;
        height: 30px;
        width: 30px;
        vertical-align: text-bottom;
        margin-right: 5px;
    }

    .title {
        color: @brand-color;
        text-transform: uppercase;
        margin-bottom: rem(15px);
        font-size: rem(26px);
        font-weight:bold;
        display: inline-block;

    }



    .paragraph-element, .image-element, ul, ol, .iframe-element, .video-element, .audio-element, .corousel-element {
        margin-bottom: 30px;


        &.-nomargin {
            margin-bottom: 0;
        }
    }


    ul, ol {
        margin-left: 60px;

        li {
            margin-bottom: 10px;
        }
    }
}


@media only screen and (max-width: @small-break-point) { 
    .question-item {
        .title { 
            font-size: rem(22px);
            line-height: rem(32px);
            font-weight: demi;
        }

        .paragraph-element, .image-element, ul, ol, .iframe-element, .video-element, .audio-element, .carousel-element {
            margin-bottom: 15px;
        }
    }
 }